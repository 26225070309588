<!-- Wash Tab (The Homepage) -->
<div class="wrapper">
  <div
    id="carouselExampleSlidesOnly"
    class="carousel slide"
    data-ride="carousel"
  >
    <div class="carousel-inner">
      <!-- Carousel Picture 1 -->
      <div
        class="carousel-item active"
        (click)="navigate()"
        style="cursor: pointer"
      >
        <div class="pic1 d-block">
          <img
            src="../../assets/img/yawlit-v2.jpeg"
            class="dn"
            alt=""
            style="width: 100%"
          />
          <img
            src="../../assets/img/yawlit-v2.jpeg"
            class="dp"
            alt=""
            style="width: 100%"
          />
        </div>
      </div>
      <!-- Carousel Picture 2 -->
      <!-- <div class="carousel-item">
        <div class="pic2 d-block w-100">
          <img
            src="../../assets/img/2.jpg"
            class="dn"
            alt=""
            style="width: 100%"
          />
          <img
            src="../../assets/img/2p.jpg"
            class="dp"
            alt=""
            style="width: 100%"
          />
        </div>
      </div> -->
      <!-- Carousel Picture 3 -->
      <!-- <div class="carousel-item">
        <div class="pic3 d-block w-100">
          <img
            src="../../assets/img/3.jpg"
            class="dn"
            alt=""
            style="width: 100%"
          />
          <img
            src="../../assets/img/3p.jpg"
            class="dp"
            alt=""
            style="width: 100%"
          />
        </div>
      </div> -->
    </div>
  </div>
</div>
<!-- Rendering the About Section -->
<div class="ab">
  <app-about></app-about>
</div>
