<!-- Thank You page after transaction is complete -->
<div class="wrapper">
    <div class="tyimg d-block w-100">
        <img src="../../assets/img/thank you.png" alt="">
    </div>
    <!-- Buttons to navigate to other pages -->
    <div class="gobackbtn">
        <div class="btn1">
            <button class="btn btn-dark fixedbtnstyle b2" routerLink="/yourplans">View Your orders</button>
        </div>
        <div class="btn2">
            <button class="btn btn-dark fixedbtnstyle b1" routerLink="">Go back to Homepage</button>
        </div>
    </div>
</div>