// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase:
  {
  apiKey: "AIzaSyA55YuMkxCX515ORd6kVGSjMjKV501zBM0",
  authDomain: "yawlit.com",
  databaseURL: "https://ecc-web-de030.firebaseio.com",
  projectId: "ecc-web-de030",
  storageBucket: "ecc-web-de030.appspot.com",
  messagingSenderId: "490869876470",
  appId: "1:490869876470:web:cc29034f00c651e8132ed7",
  measurementId: "G-62W0FRN1EH"
  },
  cloudFunctions : {
    createOrder: 'https://us-central1-ecc-web-de030.cloudfunctions.net/createOrder',
    capturePayment: 'https://us-central1-ecc-web-de030.cloudfunctions.net/capturePayments'},
    RAZORPAY_KEY_ID: 'rzp_test_DKO9FyqO14EdFC'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
