<!-- HomePage after tabs -->
<div class="main-wrapper">
  <!-- UNIQUE PROPOSITION -->
  <div class="wrapper">
    <hr />
    <div class="wrap wrap1">
      <div class="leftwrap ww">
        <img src="../../assets/img/save-water-transparent.png" alt="" />
      </div>
      <div class="rightwrap ww">
        <div class="text t1">
          <p class="bigtext">Save Water Save Life</p>
          <p class="smalltext">
            To wash a Car without using technology and expertise a person would
            require above 30 Litres of water. Yawlit uses technology and
            training to reduce the excess wastage of water.
          </p>
          <p class="smalltext sm1">
            <a href="#" class="yellowtext">#Yawlit</a>💓Wash now with only 3
            Litres* of water!<a href="#" class="yellowtext">
              #YawlitSavesWater</a
            >
          </p>
        </div>
      </div>
    </div>
    <hr />
    <div class="wrap wrap2">
      <div class="leftwrap ww">
        <div class="text t2">
          <p class="bigtext">Clean Healthy Environment and You</p>
          <p class="smalltext">
            Filthier than the average toilet and harboring a horde of spiteful
            germs, vehicular hygiene is the focal point of our testament to
            revolutionize this passive automobile industry and ensure a pristine
            driving experience to our patrons.
            <a href="#" class="yellowtext">#YawlitNow</a>
          </p>
        </div>
      </div>
      <div class="rightwrap ww">
        <img src="../../assets/img/che-transparent.png" alt="" />
      </div>
    </div>
    <hr />
    <div class="wrap wrap3">
      <div class="leftwrap ww">
        <img src="../../assets/img/sp-transparent.png" alt="" />
      </div>
      <div class="rightwrap ww">
        <div class="text t3">
          <p class="bigtext">Certified and Trained Partners</p>
          <p class="smalltext">
            Every Yawlit partner is trained to use industry first technology and
            knows about your vehicle throughout!
          </p>
          <p class="smalltext sm1">
            Sit back and watch us take care of your automobile the way it should
            be done!
            <a href="#" class="yellowtext">#YawlitPartner</a>
          </p>
        </div>
      </div>
    </div>
    <hr />
    <div class="wrap wrap4">
      <div class="leftwrap ww">
        <div class="text t4">
          <p class="bigtext">Premium Products Used</p>
          <p class="smalltext">
            Only Premium products and technology is used in every
            <a href="#" class="yellowtext"> #Yawlit</a>💓Wash. All the Premium
            Services and Products follow industry standards and are streamlined
            by industry experts.
          </p>
          <p class="smalltext sm1">
            Treat your Premium Investment with Premium Service!
            <a href="#" class="yellowtext"> #YawlitNow</a>
          </p>
        </div>
      </div>
      <div class="rightwrap ww">
        <img src="../../assets/img/premium-content-transparent.png" alt="" />
      </div>
    </div>
    <hr />
    <div class="wrap wrap5">
      <div class="leftwrap ww">
        <img src="../../assets/img/fast-service-transparent.png" alt="" />
      </div>
      <div class="rightwrap ww">
        <div class="text t5">
          <p class="bigtext">Convenient And Hassle-Free Service</p>
          <p class="smalltext">
            Yawlit combines modern day technology with skilled servicemen the
            way it should be done. You should book the service and we will be
            right there with our expertise and on-time service.
          </p>
        </div>
      </div>
    </div>
    <hr />
  </div>

  <!-- How we serve -->
  <div class="wrapper-2" id="howWeServe">
    <p>HOW WE SERVE</p>
    <div
      id="carouselExampleSlidesOnly"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            class="hws-img-1"
            src="../../assets/img/service poster_website.jpg"
            alt=""
          />
          <img
            class="hws-img-2"
            src="../../assets/img/service-portrait.jpg"
            alt=""
          />
        </div>
        <div class="carousel-item">
          <div class="hwetext">
            <ul>
              <li>
                <fa-icon [icon]="faChevronCircleRight"></fa-icon> Our community
                of spearheaded trailblazers assure exemplary proficiency in our
                cleaning operations.
              </li>
              <li>
                <fa-icon [icon]="faChevronCircleRight"></fa-icon> To explore our
                exquisite range of expertise, all one needs to do is simply book
                a service and select a suitable time slot.
              </li>
              <li>
                <fa-icon [icon]="faChevronCircleRight"></fa-icon> Thereafter, a
                professional shall be allotted to tend to your automobile and
                provide you with the finest service in this trade.
              </li>
              <li>
                <fa-icon [icon]="faChevronCircleRight"></fa-icon> Following a
                few elementary steps, one can select the mode of payment and
                post clearance of the same shall receive a confirmation of the
                remittance in due course, subjective to mode of payment
                discharge.
              </li>
              <li>
                <fa-icon [icon]="faChevronCircleRight"></fa-icon> After that you
                can sit back and relax while our merry band of servicemen carry
                out a flawless operation to present a trademarked service worthy
                of our brand.
              </li>
              <li>
                <fa-icon [icon]="faChevronCircleRight"></fa-icon> Once our auto
                wizards conclude their magic on your vehicle and fulfill their
                discreet assignments in the stipulated time frame, a testament
                of the same shall be duly sent to your person, thus concluding
                this immaculate process and making you a part of a legacy in the
                making.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- About us -->
  <div class="wrapper-4" id="about">
    <p>ABOUT US</p>
    <div class="text">
      <p>
        Yawlit is a company driven by the noble aspirations of iron-willed
        enthusiasts for passionate personalities. We put our products through
        rigorous tests to ensure that our unit becomes a part of you when you
        install it on your automobile and never makes you regret your instinct
        to become a part of our close-knit family. We offer you a seamless
        product, thoroughly tested, road and track ready, custom fabricated and
        made with boundless love, shipped within a heartbeat to your very door.
      </p>

      <!-- <p>We are a unique entity with wizards of technology. Unlike everyone else, we firmly stand by our products with a steady warranty, accountable customer support and a herculean knowledge base to help you procure the product privileged enough
                to be in your dream build ! We are an evolving, spirited, tech-driven lifestyle brand with a vibrant vision to impact the lives of our beloved consumers and become pioneers in the highly competitive automobile, energy and manufacturing
                sectors.
            </p> -->

      <p>
        Whatever the products on our website be, you can be assured that they
        are personally intermingled with us and, tried and tested on our own
        automobiles as well for we are not mere traders of third party goods,
        but much more than that. We believe that every unit with us has a soul,
        thus, we nurture them with tender care and craft them according to the
        dictum of <strong>'Quality, Consistency, Beauty and Longevity'.</strong>
      </p>

      <p>
        Yawlit has its roots in Kolkata, the city of love and joy, the ideals of
        which shape our very foundations.
      </p>

      <p>
        Our fraternity of servicemen and women, associated with automobiles for
        over 10 years, hold decades worth of mechanical knowledge and are ever
        eager to guide you through your purchases. Feel free to drop us a line,
        use our contact page or email us at careers@yawlit.com or check out our
        Facebook page and our blog for the grossing updates and stay tuned for
        our regular posts to help you connect with all the pulsating journeys we
        make and the passionate projects we undertake.
      </p>
    </div>
  </div>

  <!-- team -->
  <div class="w3" id="team">
    <p>TEAM</p>
    <div class="wrapper-3">
      <div class="box box1">
        <div class="inbx">
          <div class="image">
            <img src="../../assets/img/kabir.jpg" alt="" />
          </div>
          <div class="boxdown">
            <p class="bluename">Abhishek Kabir</p>
            <p class="greytext">Co-Founder & CEO</p>
            <div class="insidebox">
              <p>
                He is leading at the edge of technology with smart, strategic
                and enterprising ways and is obsessed with anything on wheels.
                He stands tall at the helm of this visionary consortium with an
                ambition to become a defining force in the tech revolution.
              </p>
            </div>
          </div>
        </div>
        <div class="contct">
          <a
            href="https://www.facebook.com/abhishek.kabir"
            target="_blank"
            class="i1 ic"
          >
            <fa-icon [icon]="faFacebook"></fa-icon>
          </a>

          <a
            href="https://www.instagram.com/_abhishekkabir_/"
            target="_blank"
            class="i2 ic"
          >
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
          <a href="https://twitter.com/iamakabir" target="_blank" class="i3 ic">
            <fa-icon [icon]="faTwitter"></fa-icon>
          </a>
          <a
            href="https://www.linkedin.com/in/abhishekkabir"
            target="_blank"
            class="i4 ic"
          >
            <fa-icon [icon]="faLinkedin"></fa-icon>
          </a>
        </div>
      </div>
      <div class="box box2">
        <div class="inbx">
          <div class="image">
            <img src="../../assets/img/snehendu.jpeg" alt="" />
          </div>
          <div class="boxdown">
            <p class="bluename">Snehendu Datta</p>
            <p class="greytext">Co-Founder & COO</p>
            <div class="insidebox">
              <p>
                He is a man of his words dedicated to his commitment to ensure
                the smoothest possible service. Standing tall as a beacon
                driving our forces forward, he brings out the best in his
                associates and nurtures the very foundation of this company.
              </p>
            </div>
          </div>
        </div>
        <div class="contct">
          <a
            href="https://www.facebook.com/that.dank.guy.from.kolkata"
            target="_blank"
            class="i1 ic"
          >
            <fa-icon [icon]="faFacebook"></fa-icon>
          </a>

          <a
            href="https://www.instagram.com/that_dank_guy_from_kolkata/"
            target="_blank"
            class="i2 ic"
          >
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
          <a
            href="https://twitter.com/snehendu_datta"
            target="_blank"
            class="i3 ic"
          >
            <fa-icon [icon]="faTwitter"></fa-icon>
          </a>
          <a
            href="https://www.linkedin.com/in/dattasnehendu"
            target="_blank"
            class="i4 ic"
          >
            <fa-icon [icon]="faLinkedin"></fa-icon>
          </a>
        </div>
      </div>
      <!-- <div class="box box3">
        <div class="inbx">
          <div class="image">
            <img src="../../assets/img/suman.jpeg" alt="" />
          </div>
          <div class="boxdown">
            <p class="bluename">Suman Ghosh</p>
            <p class="greytext">Co-Founder & CPO</p>
            <div class="insidebox">
              <p>
                He ascends beyond the rest, driven by his passion & a vigorous
                determination to solve every riddle in the box. A man of a
                thousand hats, he is a perfectionist at his work, firmly
                resolved to find the missing piece in each puzzle and present a
                seamless product for you to behold.
              </p>
            </div>
          </div>
        </div>
        <div class="contct">
          <a
            href="https://www.facebook.com/suman.ghosh.39589149"
            target="_blank"
            class="i1 ic"
          >
            <fa-icon [icon]="faFacebook"></fa-icon>
          </a>

          <a
            href="https://www.instagram.com/the_imperfect_soul_ever/"
            target="_blank"
            class="i2 ic"
          >
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
        </div>
      </div> -->
    </div>
  </div>

  <!-- contact & footer -->
  <div class="wrapper-5" id="footer">
    <div class="smallwrap">
      <div class="part part1">
        <div class="image">
          <img src="../../assets/img/logo1.png" alt="" height="50" />
        </div>
        <div class="text">
          <p>YAWLIT</p>
          <div class="points">
            <ul>
              <li><a href="/earns">Earns</a></li>
              <li><a href="/plans">Plans</a></li>
              <li><a href="/services">Services</a></li>
              <li>
                <a
                  class="termslink"
                  (click)="openScrollableContent(longContent)"
                  >Terms</a
                >
              </li>
            </ul>
          </div>
        </div>

        <ng-template #content let-modal>
          <div class="modal-header">
            <h4 class="modal-title">Modal title</h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>One fine body&hellip;</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light"
              (click)="modal.close('Close click')"
            >
              Close
            </button>
          </div>
        </ng-template>
      </div>
      <div class="part part2">
        <div class="getintouch">
          <p class="part3heading">Get In Touch</p>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <input
              type="text"
              formControlName="name"
              class="gitcontent"
              placeholder="Name"
            />
            <input
              type="text"
              formControlName="phone"
              class="gitcontent"
              placeholder="Phone Number(ex: 9213456789)"
            />
            <input
              class="gitcontent"
              formControlName="email"
              type="email"
              name="senderemail"
              placeholder="Email Address"
              id="senderemail"
            />
            <textarea
              formControlName="message"
              name="getintouch"
              placeholder="We would love to hear from you!"
              class="gitcontent"
              id="git"
              cols="35"
              rows="7"
            ></textarea>
            <div class="gitcontent gitb">
              <button
                type="submit"
                [disabled]="!form.valid"
                class="btn btn-dark whitebtn"
              >
                <fa-icon [icon]="faPaperPlane" transform="left-4"></fa-icon>
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="part part3">
        <p class="part3heading">Contact Us</p>
        <div class="address p3h">
          <fa-icon class="owg" [icon]="faMapPin"></fa-icon>
          <p class="owg">
            <a href="https://maps.app.goo.gl/xsfcamFBwEahFTHX6" target="_blank"
              >Address: 1113, Madurdaha Hossainpur, Kolkata-700107</a
            >
          </p>
        </div>
        <div class="contacts p3h">
          <fa-icon class="owg" [icon]="faEnvelope"></fa-icon>
          <p class="owg">Email: Yawlit@outlook.com</p>
        </div>
        <div class="contacts p3h">
          <fa-icon class="owg" [icon]="faPhoneAlt"></fa-icon>
          <p class="owg">Phone: (+91)7980866011</p>
        </div>
      </div>
    </div>

    <div class="lastpart">
      <div class="lp copyright">
        <p class="copyrg">
          <fa-icon [icon]="faCopyright"></fa-icon> Copyright 2020 Yawlit - All
          Rights Reserved
        </p>
      </div>

      <div class="connect lp">
        <div class="icons">
          <a href="#" class="i1 ic">
            <fa-icon [icon]="faFacebook"></fa-icon>
          </a>
          <a href="#" class="i2 ic">
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
          <a href="#" class="i3 ic">
            <fa-icon [icon]="faTwitter"></fa-icon>
          </a>
          <a href="#" class="i4 ic">
            <fa-icon class="fali" [icon]="faLinkedin"></fa-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TERMS AND CONDITIONS -->
<ng-template #longContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title termshead">Yawlit Terms and Conditions</h4>
  </div>
  <div class="termsmodal modal-body">
    <p class="header">OVERVIEW</p>
    <p class="body">
      This website is operated by Yawlit Automotives Private Limited. Throughout
      the site, the terms “we”, “us” and “our” refer to Yawlit Automotives
      Private Limited. Yawlit Automotives Private Limited offers this website,
      including all information, tools and services available from this site to
      you, the user, conditioned upon your acceptance of all terms, conditions,
      policies and notices stated here. By visiting our site and/ or purchasing
      something from us, you engage in our “Service” and agree to be bound by
      the following terms and conditions (“Terms of Service”, “Terms”),
      including those additional terms and conditions and policies referenced
      herein and/or available by our website. These Terms of Service apply to
      all users of the site, including without limitation users who are
      browsers, vendors, customers, merchants, and/ or contributors of content.
      Please read these Terms of Service carefully before accessing or using our
      website. By accessing or using any part of the site, you agree to be bound
      by these Terms of Service. If you do not agree to all the terms and
      conditions of this agreement, then you may not access the website or use
      any services. If these Terms of Service are considered an offer,
      acceptance is expressly limited to these Terms of Service. Any new
      features which are added to the current store shall also be subject to the
      Terms of Service. You can review the most current version of the Terms of
      Service at any time on this page. We reserve the right to update, change
      or replace any part of these Terms of Service by posting updates and/or
      changes to our website. It is your responsibility to check this page
      periodically for changes. Your continued use of or access to the website
      following the posting of any changes constitutes acceptance of those
      changes.
    </p>
    <p class="header">GENERAL CONDITIONS</p>
    <p class="body">
      We reserve the right to refuse service to anyone for any reason at any
      time. You understand that your content (not including credit card
      information), may be transferred unencrypted and involve (a) transmissions
      over various networks; and. Credit card information is always encrypted
      during transfer over networks. You agree not to reproduce, duplicate,
      copy, sell, resell or exploit any portion of the Service, use of the
      Service, or access to the Service or any contact on the website through
      which the service is provided, without express written permission by us.
      The headings used in this agreement are included for convenience only and
      will not limit or otherwise affect these Terms.
    </p>
    <p class="header">ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</p>
    <p class="body">
      We are not responsible if information made available on this site is not
      accurate, complete or current. The material on this site is provided for
      general information only and should not be relied upon or used as the sole
      basis for making decisions without consulting primary, more accurate, more
      complete or more timely sources of information. Any reliance on the
      material on this site is at your own risk. This site may contain certain
      historical information. Historical information, necessarily, is not
      current and is provided for your reference only. We reserve the right to
      modify the contents of this site at any time, but we have no obligation to
      update any information on our site. You agree that it is your
      responsibility to monitor changes to our site.
    </p>
    <p class="header">MODIFICATIONS TO THE SERVICE AND PRICES</p>
    <p class="body">
      Prices for our service are subject to change without notice. We reserve
      the right at any time to modify or discontinue the Service (or any part or
      content thereof) without notice at any time. We shall not be liable to you
      or to any third-party for any modification, price change, suspension or
      discontinuance of the Service.
    </p>
    <p class="header">PRODUCTS OR SERVICES</p>
    <p class="body">
      Certain products or services may be available to see exclusively online
      through the website. We may exercise this right on a case-by-case basis.
      We reserve the right to limit the quantities of any products or services
      that we offer. All descriptions of services or service pricing are subject
      to change at any time without notice, at the sole discretion of us. We
      reserve the right to discontinue any service at any time. Any offer for
      any service made on this site is void where prohibited. We do warrant that
      the quality of any (products,) services, information, or other material
      purchased or obtained by you will meet your expectations, or that any
      errors in the Service will be corrected.
    </p>
    <p class="header">ACCURACY OF BILLING AND ACCOUNT INFORMATION</p>
    <p class="body">
      We reserve the right to refuse any order you place with us. We may, in our
      sole discretion, limit or cancel quantities purchased per person, per
      household or per order. These restrictions may include orders placed by or
      under the same customer account, the same credit card, and/or orders that
      use the same billing and/or shipping address. In the event that we make a
      change to or cancel an order, we may attempt to notify you by contacting
      the e-mail and/or billing address/phone number provided at the time the
      order was made. We reserve the right to limit or prohibit orders that, in
      our sole judgment, appear to be placed by dealers, resellers or
      distributors. You agree to provide current, complete and accurate purchase
      and account information for all purchases made at our store. You agree to
      promptly update your account and other information, including your email
      address and credit card numbers and expiration dates, so that we can
      complete your transactions and contact you as needed. For more detail,
      please review our Returns Policy.
    </p>
    <p class="header">THIRD-PARTY LINKS</p>
    <p class="body">
      Certain content, products and services available via our Service may
      include materials from third-parties. Third-party links on this site may
      direct you to third-party websites that are not affiliated with us. We are
      not responsible for examining or evaluating the content or accuracy and we
      do not warrant and will not have any liability or responsibility for any
      third-party materials or websites, or for any other materials, products,
      or services of third-parties. We are not liable for any harm or damages
      related to the purchase or use of goods, services, resources, content, or
      any other transactions made in connection with any third-party websites.
      Please review carefully the third-party's policies and practices and make
      sure you understand them before you engage in any transaction. Complaints,
      claims, concerns, or questions regarding third-party products should be
      directed to the third-party.
    </p>

    <p class="header">USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</p>
    <p class="body">
      If, at our request, you send certain specific submissions (for example
      contest entries) or without a request from us you send creative ideas,
      suggestions, proposals, plans, or other materials, whether online, by
      email, by postal mail, or otherwise (collectively, 'comments'), you agree
      that we may, at any time, without restriction, edit, copy, publish,
      distribute, translate and otherwise use in any medium any comments that
      you forward to us. We are and shall be under no obligation (1) to maintain
      any comments in confidence; (2) to pay compensation for any comments; or
      (3) to respond to any comments. We may, but have no obligation to,
      monitor, edit or remove content that we determine in our sole discretion
      are unlawful, offensive, threatening, libelous, defamatory, pornographic,
      obscene or otherwise objectionable or violates any party’s intellectual
      property or these Terms of Service. You agree that your comments will not
      violate any right of any third-party, including copyright, trademark,
      privacy, personality or other personal or proprietary right. You further
      agree that your comments will not contain libelous or otherwise unlawful,
      abusive or obscene material, or contain any computer virus or other
      malware that could in any way affect the operation of the Service or any
      related website. You may not use a false e-mail address, pretend to be
      someone other than yourself, or otherwise mislead us or third-parties as
      to the origin of any comments. You are solely responsible for any comments
      you make and their accuracy. We take no responsibility and assume no
      liability for any comments posted by you or any third-party.
    </p>

    <p class="header">PERSONAL INFORMATION</p>
    <p class="body">
      Your submission of personal information through the store is governed by
      our Privacy Policy.
    </p>

    <p class="header">ERRORS, INACCURACIES AND OMISSIONS</p>
    <p class="body">
      Occasionally there may be information on our site or in the Service that
      contains typographical errors, inaccuracies or omissions that may relate
      to product descriptions, pricing, promotions, offers, product shipping
      charges, transit times and availability. We reserve the right to correct
      any errors, inaccuracies or omissions, and to change or update information
      or cancel orders if any information in the Service or on any related
      website is inaccurate at any time without prior notice (including after
      you have submitted your order). We undertake no obligation to update,
      amend or clarify information in the Service or on any related website,
      including without limitation, pricing information, except as required by
      law. No specified update or refresh date applied in the Service or on any
      related website, should be taken to indicate that all information in the
      Service or on any related website has been modified or updated.
    </p>

    <p class="header">PROHIBITED USES</p>
    <p class="body">
      In addition to other prohibitions as set forth in the Terms of Service,
      you are prohibited from using the site or its content: (a) for any
      unlawful purpose; (b) to solicit others to perform or participate in any
      unlawful acts; (c) to violate any international, federal, provincial or
      state regulations, rules, laws, or local ordinances; (d) to infringe upon
      or violate our intellectual property rights or the intellectual property
      rights of others; (e) to harass, abuse, insult, harm, defame, slander,
      disparage, intimidate, or discriminate based on gender, sexual
      orientation, religion, ethnicity, race, age, national origin, or
      disability; (f) to submit false or misleading information; (g) to upload
      or transmit viruses or any other type of malicious code that will or may
      be used in any way that will affect the functionality or operation of the
      Service or of any related website, other websites, or the Internet; (h) to
      collect or track the personal information of others; (i) to spam, phish,
      pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral
      purpose; or (k) to interfere with or circumvent the security features of
      the Service or any related website, other websites, or the Internet. We
      reserve the right to terminate your use of the Service or any related
      website for violating any of the prohibited uses.
    </p>

    <p class="header">DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</p>
    <p class="body">
      We do not guarantee, represent or warrant that your use of our service
      will be uninterrupted, timely, secure or error-free. We do not warrant
      that the results that may be obtained from the use of the service will be
      accurate or reliable. You agree that from time to time we may remove the
      service for indefinite periods of time or cancel the service at any time,
      without notice to you. You expressly agree that your use of, or inability
      to use, the service is at your sole risk. The service and all products and
      services delivered to you through the service are (except as expressly
      stated by us) provided 'as is' and 'as available' for your use, without
      any representation, warranties or conditions of any kind, either express
      or implied, including all implied warranties or conditions of
      merchantability, merchantable quality, fitness for a particular purpose,
      durability, title, and non-infringement. In no case shall Yawlit
      Automotives Private Limited., our directors, officers, employees,
      affiliates, agents, contractors, interns, suppliers, service providers or
      licensors be liable for any injury, loss, claim, or any direct, indirect,
      incidental, punitive, special, or consequential damages of any kind,
      including, without limitation lost profits, lost revenue, lost savings,
      loss of data, replacement costs, or any similar damages, whether based in
      contract, tort (including negligence), strict liability or otherwise,
      arising from your use of any of the service or any products procured using
      the service, or for any other claim related in any way to your use of the
      service or any product, including, but not limited to, any errors or
      omissions in any content, or any loss or damage of any kind incurred as a
      result of the use of the service or any content (or product) posted,
      transmitted, or otherwise made available via the service, even if advised
      of their possibility. Because some states or jurisdictions do not allow
      the exclusion or the limitation of liability for consequential or
      incidental damages, in such states or jurisdictions, our liability shall
      be limited to the maximum extent permitted by law.
    </p>

    <p class="header">SEVERABILITY</p>
    <p class="body">
      In the event that any provision of these Terms of Service is determined to
      be unlawful, void or unenforceable, such provision shall nonetheless be
      enforceable to the fullest extent permitted by applicable law, and the
      unenforceable portion shall be deemed to be severed from these Terms of
      Service, such determination shall not affect the validity and
      enforceability of any other remaining provisions.
    </p>

    <p class="header">INDEMNIFICATION</p>
    <p class="body">
      You agree to indemnify, defend and hold harmless Yawlit Automotives
      Private Limited. and our parent, subsidiaries, affiliates, partners,
      officers, directors, agents, contractors, licensors, service providers,
      subcontractors, suppliers, interns and employees, harmless from any claim
      or demand, including reasonable attorneys’ fees, made by any third-party
      due to or arising out of your breach of these Terms of Service or the
      documents they incorporate by reference, or your violation of any law or
      the rights of a third-party.
    </p>

    <p class="header">TERMINATION</p>
    <p class="body">
      The obligations and liabilities of the parties incurred prior to the
      termination date shall survive the termination of this agreement for all
      purposes. These Terms of Service are effective unless and until terminated
      by either you or us. You may terminate these Terms of Service at any time
      by notifying us that you no longer wish to use our Services, or when you
      cease using our site. If in our sole judgment you fail, or we suspect that
      you have failed, to comply with any term or provision of these Terms of
      Service, we also may terminate this agreement at any time without notice
      and you will remain liable for all amounts due up to and including the
      date of termination; and/or accordingly may deny you access to our
      Services (or any part thereof).
    </p>

    <p class="header">ENTIRE AGREEMENT</p>
    <p class="body">
      The failure of us to exercise or enforce any right or provision of these
      Terms of Service shall not constitute a waiver of such right or provision.
      These Terms of Service and any policies or operating rules posted by us on
      this site or in respect to The Service constitutes the entire agreement
      and understanding between you and us and govern your use of the Service,
      superseding any prior or contemporaneous agreements, communications and
      proposals, whether oral or written, between you and us (including, but not
      limited to, any prior versions of the Terms of Service). Any ambiguities
      in the interpretation of these Terms of Service shall not be construed
      against the drafting party.
    </p>

    <p class="header">GOVERNING LAW</p>
    <p class="body">
      These Terms of Service and any separate agreements whereby we provide you
      Services shall be governed by and construed in accordance with the laws of
      India and jurisdiction of Kolkata, West Bengal.
    </p>

    <p class="header">CHANGES TO TERMS OF SERVICE</p>
    <p class="body">
      You can review the most current version of the Terms of Service at any
      time at this page. We reserve the right, at our sole discretion, to
      update, change or replace any part of these Terms of Service by posting
      updates and changes to our website. It is your responsibility to check our
      website periodically for changes. Your continued use of or access to our
      website or the Service following the posting of any changes to these Terms
      of Service constitutes acceptance of those changes.
    </p>

    <p class="header">CONTACT INFORMATION</p>
    <p class="body">
      Questions about the Terms of Service should be sent to us at
      suupport@yawlit.com.
    </p>

    <p class="header">Yawlit Refund Policy.</p>
    <p class="body">
      Yawlit takes utmost care to cater to you with the best of services and
      smooth processes. However, you can find the following to your aid in case
      you face an issue with our services No refunds offered if the attendee
      does not attend the particular session. The amount paid gets credited to
      your Yawlit account. You can choose to use this amount while buying any
      other course on our website. If any particular session is cancelled, we
      offer a later date to hold the session. If a session is cancelled
      entirely, we add 50 units to your wallet (valid for 6 months) along with
      the amount you've invested which you can debit on your next purchase.
      Under Purchase History and Your Plans sections, we review refunding
      possibilities based on customer requests mailed to support@yawlit.com.
    </p>

    <p class="header">Yawlit Disclaimer</p>
    <p class="body">
      The information provided by Yawlit ("we," "us" or "our" ) on
      www.yawlit.com (the "Site") is for general informational purposes only.
      All information on the site and our mobile application is provided in good
      faith, however we make no representation or warranty of any kind, express
      or implied, regarding the accuracy, adequacy, validity, reliability,
      availability or completeness of any information on the site. UNDER NO
      CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF
      ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY
      INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE
      ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.
    </p>

    <p class="header" style="font-size: 20px; font-family: Wavehaus-B">
      Yawlit Privacy Policy
    </p>
    <p class="header">WHAT DO WE DO WITH YOUR INFORMATION?</p>
    <p class="body">
      When you purchase something from our store, as part of the buying and
      selling process, we collect the personal information you give us such as
      your name, address and email address. When you browse our store, we also
      automatically receive your computer’s internet protocol (IP) address in
      order to provide us with information that helps us learn about your
      browser and operating system. Email marketing (if applicable): With your
      permission, we may send you emails about our store, new products and other
      updates.
    </p>

    <p class="header">CONSENT</p>
    <p class="body">
      How do you get my consent? When you provide us with personal information
      to complete a transaction, verify your credit card, place an order, return
      a purchase, we imply that you consent to our collecting it and using it
      for that specific reason only. If we ask for your personal information for
      a secondary reason, like marketing, we will either ask you directly for
      your expressed consent, or provide you with an opportunity to say no. How
      do I withdraw my consent? If after you opt-in, you change your mind, you
      may withdraw your consent for us to contact you, for the continued
      collection, use or disclosure of your information, at any time, by
      contacting us at support@yawlit.com .
    </p>

    <p class="header">DISCLOSURE</p>
    <p class="body">
      We may disclose your personal information if we are required by law to do
      so or if you violate our Terms of Service.
    </p>

    <p class="header">PAYMENT</p>
    <p class="body">
      We use Razorpay for processing payments. We/Razorpay do not store your
      card data on their servers. The data is encrypted through the Payment Card
      Industry Data Security Standard (PCI-DSS) when processing payment. Your
      purchase transaction data is only used as long as is necessary to complete
      your purchase transaction. After that is complete, your purchase
      transaction information is not saved. Our payment gateway adheres to the
      standards set by PCI-DSS as managed by the PCI Security Standards Council,
      which is a joint effort of brands like Visa, MasterCard, American Express
      and Discover. PCI-DSS requirements help ensure the secure handling of
      credit card information by our store and its service providers. For more
      insight, you may also want to read terms and conditions of razorpay.
    </p>

    <p class="header">THIRD-PARTY SERVICES</p>
    <p class="body">
      In general, the third-party providers used by us will only collect, use
      and disclose your information to the extent necessary to allow them to
      perform the services they provide to us. However, certain third-party
      service providers, such as payment gateways and other payment transaction
      processors, have their own privacy policies in respect to the information
      we are required to provide to them for your purchase-related transactions.
      For these providers, we recommend that you read their privacy policies so
      you can understand the manner in which your personal information will be
      handled by these providers. In particular, remember that certain providers
      may be located in or have facilities that are located a different
      jurisdiction than either you or us. So if you elect to proceed with a
      transaction that involves the services of a third-party service provider,
      then your information may become subject to the laws of the
      jurisdiction(s) in which that service provider or its facilities are
      located. Once you leave our store’s website or are redirected to a
      third-party website or application, you are no longer governed by this
      Privacy Policy or our website’s Terms of Service. Links When you click on
      links on our store, they may direct you away from our site. We are not
      responsible for the privacy practices of other sites and encourage you to
      read their privacy statements.
    </p>

    <p class="header">SECURITY</p>
    <p class="body">
      To protect your personal information, we take reasonable precautions and
      follow industry best practices to make sure it is not inappropriately
      lost, misused, accessed, disclosed, altered or destroyed.
    </p>

    <p class="header">COOKIES</p>
    <p class="body">
      We use cookies to maintain session of your user. It is not used to
      personally identify you on other websites.
    </p>

    <p class="header">AGE OF CONSENT</p>
    <p class="body">
      By using this site, you represent that you are at least the age of
      majority in your state or province of residence, or that you are the age
      of majority in your state or province of residence and you have given us
      your consent to allow any of your minor dependents to use this site.
    </p>

    <p class="header">CHANGES TO THIS PRIVACY POLICY</p>
    <p class="body">
      We reserve the right to modify this privacy policy at any time, so please
      review it frequently. Changes and clarifications will take effect
      immediately upon their posting on the website. If we make material changes
      to this policy, we will notify you here that it has been updated, so that
      you are aware of what information we collect, how we use it, and under
      what circumstances, if any, we use and/or disclose it. If our store is
      acquired or merged with another company, your information may be
      transferred to the new owners so that we may continue to sell products to
      you.
    </p>

    <p class="header">QUESTIONS AND CONTACT INFORMATION</p>
    <p class="body">
      If you would like to: access, correct, amend or delete any personal
      information we have about you, register a complaint, or simply want more
      information contact our Privacy Compliance Officer at support@yawlit.com.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-light"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>
