
<!-- This is the form for the add services section-->
<div class="full-contain">
    <p class="heading">Add New Service</p>
    <div class="form-container">
      <form #servicenew="ngForm" (ngSubmit)="saveService(servicenew.value)">
  
  
        <div class="form-group">
          <label for="Vehicle"><p>Vehicle</p> </label>
          <select class="form-control" #Vehicle="ngModel" [(ngModel)]="updatedService.Vehicle" name="Vehicle" id="Vehicle" required>
            <option>Bike</option>
            <option>Hatchback</option>
            <option>Sedan</option>
            <option>SUV</option>
          </select>
        </div>
        
        <div class="form-group">
          <label for="ServiceType"><p>Service Type</p> </label>
          <select class="form-control" #ServiceType="ngModel" [(ngModel)]="updatedService.ServiceType" name="ServiceType" id="ServiceType" required>
            <option>Simple</option>
            <option>Premium</option>
          </select>
        </div>
  
        <div class="form-group">
          <label for="ServiceName"><p>Service Name</p> </label>
          <input #ServiceName="ngModel" [(ngModel)]="updatedService.ServiceName" name="ServiceName" id="ServiceName" type="text" class="form-control" required>
          <div class="alert alert-danger" *ngIf="ServiceName.touched && ServiceName.invalid">
            Required
          </div>
        </div>
  
        <div class="form-group">
          <label for="Price"><p>Price</p> </label>
          <input #Price="ngModel" [(ngModel)]="updatedService.Price" name="Price" id="Price" type="number" class="form-control" required>
          <div class="alert alert-danger" *ngIf="Price.touched && Price.invalid">
            Price is Required
          </div>
        </div>
  
        <div class="form-group">
          <label for="Saving"><p>Savings</p> </label>
          <input #Saving="ngModel" [(ngModel)]="updatedService.Saving" name="Saving" id="Saving" type="number" class="form-control" required>
          <div class="alert alert-danger" *ngIf="Saving.touched && Saving.invalid">
            Required
          </div>
        </div>
  
        <div class="form-group">
          <label for="Plan1"><p>Plan Inclusions 1</p> </label>
          <input #Plan1="ngModel" [(ngModel)]="updatedService.Plan1" name="Plan1" id="Plan1" type="text" class="form-control" required>
          <div class="alert alert-danger" *ngIf="Plan1.touched && Plan1.invalid">
            Required
          </div>
        </div>
  
        <div class="form-group">
          <label for="Plan2"><p>Plan Inclusions 2</p> </label>
          <input #Plan2="ngModel" [(ngModel)]="updatedService.Plan2" name="Plan2" id="Plan2" type="text" class="form-control" required>
          <div class="alert alert-danger" *ngIf="Plan2.touched && Plan2.invalid">
            Required
          </div>
        </div>
  
  
        <div class="form-group">
          <label for="Plan3"><p>Plan Inclusions 3</p> </label>
          <input #Plan3="ngModel" [(ngModel)]="updatedService.Plan3" name="Plan3" id="Plan3" type="text" class="form-control" required>
          <div class="alert alert-danger" *ngIf="Plan3.touched && Plan3.invalid">
            Required
          </div>
        </div>
  
        <div class="form-group">
          <label for="imageUrl"><p>Image Url</p> </label>
          <input #imageUrl="ngModel" [(ngModel)]="updatedService.imageUrl" name="imageUrl" id="imageUrl" type="text" class="form-control" required url>
          <div class="alert alert-danger" *ngIf="imageUrl.touched && imageUrl.invalid">
            <div *ngIf="imageUrl.errors.required">*Required*</div>
            <div *ngIf="imageUrl.errors.url">Image url should be valid</div>
          </div>
        </div>
  
        <div class="buttonsave">
          <button class="btn" [disabled]="!servicenew.valid">Save</button>
        </div>
      </form>
    </div>
  </div>