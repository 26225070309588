import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-wash",
  templateUrl: "./wash.component.html",
  styleUrls: ["./wash.component.css"],
})
export class WashComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  navigate = (): void => {
    console.log("Navigating");
    /**
     * TODO
     */
    window.open(
      "https://play.google.com/store/apps/details?id=com.yawlit",
      "_blank"
    );
  };
}
